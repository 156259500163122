import React from 'react';
import Layout from '../../components/layout';
import Questions from '../../components/Questions';

const BehandlungStatischerFaeltchenMitFillern = ({
    data,
    location,
    ...props
}) => {
    return (
        <Layout
            location={location}
            title="Behandlung statischer Fältchen mit Fillern"
            noSlider
            {...props}
        >
            <p>
                Wir haben erklärt, dass mimische Fältchen mit Botulinum
                behandelt werden, um die mimischen kleinen Muskeln zu beruhigen.
            </p>
            <p>
                Ist die Haut durch ausgeprägte, jahrelange Mimik jedoch, sagen
                wir, eingebrochen, dann wird eine Substanz benötigt, diese
                eingebrochene Haut wieder aufzurichten, zu füllen. Daher der
                Begriff FILLER.
            </p>
            <p>
                Der dafür am häufigsten verwendete Wirkstoff ist Hyaluronsäure,
                ein natürlicher Bestandteil der Haut. Dessen Konzentration nimmt
                mit zunehmendem Alter der Haut ab und führt, neben der
                bleibenden Fältchen durch Mimik, zu einer vermehrten statischen
                Faltenbildung OHNE Mimikbewegung. Deswegen STATISCHE FALTEN.
            </p>
            <p>
                Doch auch Änderungen der Fettdepots im Gesicht und der Rückbau
                des Gesichtsknochens führen zu diesen mimikunabhängigen
                Fältchen.
            </p>
            <p>
                Auch wenn das Gesicht scheinbar perfekt von der Natur gestaltet
                wurde, so finden sich gelegentlich dennoch Wünsche nach noch
                perfekteren Lippen, volleren Lippen, einer betonten
                Jochbeinbogenregion, einer anderen Kinnform, einer Anhebung der
                Nasenspitze, um nur einige zu nennen.
            </p>
            <p>
                Mit Fillern können einzelne störende, statische Fältchen,
                geglättet werden.
            </p>
            <p>
                Ebenso kann das gesamte Gesicht durch Filler Injektion an
                bestimmten Punkten deutlicher frischer gestaltet werden, auch
                geliftet werden. Man spricht hier bildlich von dem
                Liquid-Lifting.
            </p>
            <p>
                Mit den modernsten Fillern sind Erfolge über einen Zeitraum von
                12 bis 18 Monaten machbar.
            </p>
            <p>
                Danach ist ein erneutes modellieren nach aktuellem Wunsch
                möglich.
            </p>

            <Questions title="Behandlung statischer Fältchen mit Fillern" />
        </Layout>
    );
};

export default BehandlungStatischerFaeltchenMitFillern;
